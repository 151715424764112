.Plugins {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.Plugins-content {
  padding: 20px;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.Plugins-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Plugins-content {
  scrollbar-width: none;
}

.Plugins-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.Plugins-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.Plugins-wrapper {
  display: flex;
  flex-direction: column;
}

.Plugins-title {
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Plugins-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 16px;
  padding: 16px;
}

.Plugins-card-item {
  border: 1px solid rgb(214, 223, 231);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .Plugins-card {
      grid-template-columns: 1fr;
  }
}

.Plugins-card-item-row {
  display: flex;
  flex-direction: row;
}

.Plugins-card-item-column {
  display: flex;
  flex-direction: column;
}

.Plugins-card-item-icon {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: rgb(207, 207, 207);
  border: none;
}

.Plugins-card-item-title {
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Plugins-card-item-subtitle {
  color: rgb(150, 150, 150);
  font-size: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}