.HomeOwner {
  height: 100%;
  width: 100%;
  background-color: white;
  user-select: none;
}

.HomeOwner-column {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.HomeOwner-column::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.HomeOwner-column::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.HomeOwner-column::-webkit-scrollbar-track {
  background-color: transparent;
}
