.Cities {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  user-select: none;
}

.cities-left-panel {
  width: 30%;
  height: 100%;
  border-right: 1px solid rgb(214, 223, 231);
}

.cities-left-tools {
  height: 9%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(214, 223, 231);
}

.cities-count-label {
  font-size: 11px;
  color: rgb(80, 80, 80);
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-left-list {
  height: 91%;
}

.cities-list-adapter {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.cities-list-adapter-index {
  width: 5%;
  font-size: 8px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-list-adapter-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cities-list-adapter-perc {
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-list-adapter-update {
  font-size: 10px;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-right-panel {
  position: relative;
  height: 100%;
  width: 100%;
}

.cities-right-content {
  height: 95%;
  width: 90%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: var(--justifyContent);
}

.cities-right-empty-content {
  text-align: center;
  font-size: 12px;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-detail-no {
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-detail-perc {
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-detail-update {
  font-size: 12px;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cities-list-adapter-index-load {
  width: 5%;
  font-size: 8px;
  color: rgba(237, 237, 237, 0.559);
  background-color: rgba(237, 237, 237, 0.559);
}

.cities-list-adapter-perc-load {
  color: rgba(237, 237, 237, 0.827);
  background-color: rgba(237, 237, 237, 0.827);
  font-size: 12px;
  font-weight: 600;
}

.cities-list-adapter-update-load {
  font-size: 10px;
  color: rgba(237, 237, 237, 0.559);
  background-color: rgba(237, 237, 237, 0.559);
}
